var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form",
      class: { mailingListPage: _vm.isForMailingListPage },
    },
    [
      _c("div", { staticClass: "formGroup formGroup--horizontal" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          staticClass: "formInput",
          attrs: { type: "text", name: "name", placeholder: "Name" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email",
            },
          ],
          staticClass: "formInput",
          attrs: { type: "email", name: "email", placeholder: "Email" },
          domProps: { value: _vm.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            },
          },
        }),
        _c(
          "button",
          { staticClass: "btn btn--primary", on: { click: _vm.subscribe } },
          [_vm._v("Subscribe")]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.successOnSubscription,
              expression: "successOnSubscription",
            },
          ],
          staticClass: "formGroup h-mt-30",
        },
        [
          _c("h3", [_vm._v("Thank you for subscribing!")]),
          _c("p", [_vm._v("You’ll be notified when we have something new.")]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errorOnSubscription,
              expression: "errorOnSubscription",
            },
          ],
          staticClass: "formGroup textColorDanger h-mt-30",
        },
        [
          _c("h3", [_vm._v("Error! Please try again later.")]),
          _c("p", [_vm._v(_vm._s(_vm.errorOnSubscription))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }